import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function RecordingsSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18.398}
      height={20}
      viewBox='0 0 18.398 20'
      {...props}
    >
      <g data-name='Group 285' transform='translate(-20)'>
        <circle
          data-name='Ellipse 43'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(33.599 2.801)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 44'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(33.599 6)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 45'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(33.599 9.2)'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2121'
          d='M37.617 13.75a.781.781 0 00.781-.781V3.125A3.128 3.128 0 0035.273 0H23.125A3.128 3.128 0 0020 3.125v13.75A3.128 3.128 0 0023.125 20h12.148a3.128 3.128 0 003.127-3.125.781.781 0 00-1.563 0 1.564 1.564 0 01-1.562 1.563h-12.15a1.564 1.564 0 01-1.562-1.562V3.125a1.564 1.564 0 011.563-1.562h12.147a1.564 1.564 0 011.562 1.563v9.844a.781.781 0 00.782.78z'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 46'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(33.599 12.4)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 47'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(33.599 15.6)'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2122'
          d='M31.672 8.735l-3.245-1.89a1.5 1.5 0 00-2.256 1.3v3.779a1.5 1.5 0 002.256 1.3l3.245-1.89a1.511 1.511 0 000-2.608zm-3.939 3.094V8.25l3.074 1.79z'
          fill={props.innercolor}
        />
        <circle
          data-name='Ellipse 48'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(23.2 6)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 49'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(23.2 12.4)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 50'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(23.2 2.801)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 51'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(23.2 15.6)'
          fill={props.outercolor}
        />
        <circle
          data-name='Ellipse 52'
          cx={0.8}
          cy={0.8}
          r={0.8}
          transform='translate(23.2 9.2)'
          fill={props.outercolor}
        />
      </g>
    </svg>
  )
}

const RecordingsMemoSvgComponent = React.memo(RecordingsSvgComponent)
export default RecordingsMemoSvgComponent
