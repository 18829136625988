import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function MainSessionSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      data-name='Group 274'
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={15.234}
      viewBox='0 0 20 15.234'
      {...props}
    >
      <path
        data-name='Path 2103'
        d='M12.95 6.294L9.596 4.348a1.526 1.526 0 00-2.291 1.325v3.892a1.522 1.522 0 002.291 1.323l3.354-1.946a1.531 1.531 0 000-2.647zM8.868 9.503V5.731l3.25 1.886z'
        fill={props.innercolor}
      />
      <path
        data-name='Path 2104'
        d='M18.438 8.164V3.125a1.564 1.564 0 00-1.562-1.562H3.125a1.564 1.564 0 00-1.562 1.562v8.984a1.564 1.564 0 001.563 1.563h13.75a1.564 1.564 0 001.563-1.562.781.781 0 011.563 0 3.128 3.128 0 01-3.125 3.125H3.125A3.128 3.128 0 010 12.109V3.125A3.128 3.128 0 013.125 0h13.75A3.128 3.128 0 0120 3.125v5.039a.781.781 0 01-1.562 0z'
        fill={props.outercolor}
      />
    </svg>
  )
}

const MainSessionMemoSvgComponent = React.memo(MainSessionSvgComponent)
export default MainSessionMemoSvgComponent
