import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import "./WSVideo.scss";

interface VideoProps {
  playAt?: number
  smallScreenClassName?: string
  divClassName?: string
}

export const WSVideo: React.FC<ReactPlayerProps & VideoProps> = ({
  playAt,
  smallScreenClassName,
  divClassName,
  ...props
}) => {
  return (
    <div
      className={smallScreenClassName ? '' : `${divClassName || 'ws-video'}`}
    >
      <ReactPlayer
        ref={(ref) => playAt && ref?.seekTo(playAt || 0)}
        className={smallScreenClassName}
        {...props}
      />
    </div>
  )
}
