import React from "react";
import "./WSSpinner.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface SpinnerProps {
  className?: string
}

export const WSSpin: React.FC<SpinnerProps> = ({className="spin"}) => {
  const antIcon = <LoadingOutlined spin />;
  return <Spin size="large" className={className} indicator={antIcon} />;
};
