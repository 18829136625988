import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function HelpDeskSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={19.863}
      viewBox='0 0 20 19.863'
      {...props}
    >
      <g data-name='Group 296'>
        <path
          data-name='Path 2132'
          d='M20 5.846v3.933a.787.787 0 01-1.573 0V5.846a1.578 1.578 0 00-1.255-1.541.787.787 0 01.315-1.541A3.156 3.156 0 0120 5.846z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2133'
          d='M19.219 12.844a.781.781 0 00-.781.78 1.563 1.563 0 01-1.562 1.561H3.125a1.563 1.563 0 01-1.562-1.561v-7.8a1.565 1.565 0 011.246-1.532.78.78 0 10-.309-1.529A3.132 3.132 0 000 5.821v7.8a3.127 3.127 0 003.125 3.122h6.094v1.559H6.484a.78.78 0 100 1.561h7.031a.78.78 0 100-1.561h-2.734v-1.556h6.094A3.127 3.127 0 0020 13.625a.781.781 0 00-.781-.781z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2134'
          d='M14.537 1.012A3.064 3.064 0 0012.245.001a2.878 2.878 0 00-1.813.633 3.488 3.488 0 00-.433.4 3.485 3.485 0 00-.432-.4A2.878 2.878 0 007.754.001a3.064 3.064 0 00-2.292 1.011 3.6 3.6 0 00-.9 2.44 4.2 4.2 0 001.095 2.734A21.831 21.831 0 008.224 8.63c.348.3.742.64 1.153 1a.938.938 0 001.247 0c.419-.371.827-.723 1.156-1.007a21.835 21.835 0 002.567-2.437 4.2 4.2 0 001.09-2.737 3.6 3.6 0 00-.9-2.437zm-8.123.889a1.79 1.79 0 011.34-.589 1.622 1.622 0 011.02.359 2.562 2.562 0 01.6.707.721.721 0 001.247 0 2.565 2.565 0 01.6-.707 1.622 1.622 0 011.02-.359 1.79 1.79 0 011.34.589 2.285 2.285 0 01.557 1.551 2.92 2.92 0 01-.792 1.9 21.065 21.065 0 01-2.408 2.281c-.271.234-.6.514-.94.815-.333-.293-.651-.567-.939-.815A21.089 21.089 0 016.648 5.35a2.92 2.92 0 01-.792-1.9 2.285 2.285 0 01.559-1.549z'
          fill={props.innercolor}
        />
      </g>
    </svg>
  )
}

const HelpDeskMemoSvgComponent = React.memo(HelpDeskSvgComponent)
export default HelpDeskMemoSvgComponent
