import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function AppGuideSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16.836}
      height={20}
      viewBox='0 0 16.836 20'
      {...props}
    >
      <g data-name='Group 298'>
        <path
          data-name='Path 2140'
          d='M15.273 9.883V1.562H2.344a.782.782 0 00-.781.781v13.1a2.333 2.333 0 01.781-.134h.781V3.906a.781.781 0 011.563 0v11.406h10.585v-1.523a.781.781 0 011.563 0v2.3a.781.781 0 01-.781.781H2.344a.781.781 0 000 1.563h13.711a.781.781 0 010 1.563H2.344A2.346 2.346 0 010 17.656V2.344A2.346 2.346 0 012.344 0h13.711a.781.781 0 01.781.781v9.1a.781.781 0 01-1.562 0z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2141'
          d='M9.843 11.641a.781.781 0 000 1.563.781.781 0 000-1.562z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2142'
          d='M10.664 9.872a.619.619 0 01.368-.574 2.927 2.927 0 001.78-2.7v-.033a2.93 2.93 0 00-5.859.033.781.781 0 001.563 0 1.367 1.367 0 012.734 0v.023a1.367 1.367 0 01-.831 1.236 2.179 2.179 0 00-1.318 2.011v.05a.781.781 0 001.563 0v-.05z'
          fill={props.innercolor}
        />
      </g>
    </svg>
  )
}

const AppGuideMemoSvgComponent = React.memo(AppGuideSvgComponent)
export default AppGuideMemoSvgComponent
