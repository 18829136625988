import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function SurveySvgComponent(props: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17.93}
      height={20}
      viewBox='0 0 17.93 20'
      {...props}
    >
      <g data-name='Group 297'>
        <path
          data-name='Path 2135'
          d='M7.617 18.438H3.125a1.564 1.564 0 01-1.562-1.562V3.125a1.564 1.564 0 011.563-1.562h9.604a1.564 1.564 0 011.562 1.563v8.281a.781.781 0 001.563 0V3.125A3.129 3.129 0 0012.73 0H3.125A3.128 3.128 0 000 3.125v13.75A3.128 3.128 0 003.125 20h4.492a.781.781 0 000-1.562z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2136'
          d='M12.73 8.593a.781.781 0 00-.781-.781H3.902a.781.781 0 100 1.562h8.047a.781.781 0 00.781-.781z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2137'
          d='M17.603 12.725a.781.781 0 00-1.09.18l-3.847 5.374a.446.446 0 01-.642.033l-2.493-2.394a.781.781 0 10-1.082 1.127l2.5 2.4a2.015 2.015 0 001.386.554h.133a2.013 2.013 0 001.432-.746l.026-.034 3.866-5.4a.781.781 0 00-.189-1.094z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2138'
          d='M11.949 4.687H3.902a.781.781 0 000 1.563h8.047a.781.781 0 000-1.562z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2139'
          d='M3.902 10.937a.781.781 0 000 1.563h4.887a.781.781 0 000-1.562z'
          fill={props.outercolor}
        />
      </g>
    </svg>
  )
}

const SurveyMemoSvgComponent = React.memo(SurveySvgComponent)
export default SurveyMemoSvgComponent
