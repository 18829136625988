import React, { useState } from 'react';
import { AppRoutes, AuthRoutes } from 'routes/Routes';
import * as QueryString from 'query-string';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import {StaticRouter}  from "react-router-dom/server"
import {
  NotificationWithIcon,
  setLocalStorageItem,
  getLocalStorageItem,
  removeStorageAndLogOut,
} from 'utils/helper';
// graphql
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  split,
  ApolloProvider,
} from '@apollo/react-hooks';
import * as fetch from 'isomorphic-fetch';
import  ws from 'isomorphic-ws';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  GRAPHQL_ERROR_MESSAGE,
  LOCAL_STORAGE_KEY,
} from 'components/utility/enums';
import { AppConfig } from 'Config/Config';
import FloatingVideo from 'floatingVideo';

``
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { WebSocket } from 'ws';

const adminCache = new InMemoryCache({});
const { REACT_APP_BASE_URL, REACT_APP_SUBSCRIPTION_URL } = AppConfig;
const ErrorLink = onError((error) => {
  const { graphQLErrors } = error;
  const {
    UNAUTHENTICATED,
    UNAUTHORIZED,
    JWT_EXPIRED,
    JWT_MALFORMED,
    TOKEN_NOT_VALID,
  } = GRAPHQL_ERROR_MESSAGE;
  if (graphQLErrors && typeof window !== 'undefined')
    graphQLErrors.map((errorGraphql: any) => {
      // console.log(errorGraphql, 'errorGraphql');
      if (
        errorGraphql.extensions.code === UNAUTHENTICATED ||
        errorGraphql.message === JWT_EXPIRED ||
        errorGraphql.message === JWT_MALFORMED ||
        errorGraphql.message === UNAUTHORIZED ||
        errorGraphql.message === TOKEN_NOT_VALID
      ) {
        const { pathname } = window.location;
        if (pathname.split('/')[2] !== 'overview') {
          removeStorageAndLogOut();
        }
      }
      return NotificationWithIcon('error', errorGraphql.message);
    });
});

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorageItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const httpLink = createHttpLink({
  uri: REACT_APP_BASE_URL,
  // fetch: fetch as any,
});

const wsLinkSubscription = new WebSocketLink({
  uri: `${REACT_APP_SUBSCRIPTION_URL}`,
  options: {
    reconnect: true,
  },
  webSocketImpl: ws,
});

// const wsLinkSubscription = new GraphQLWsLink(createClient({
//   url: `${REACT_APP_SUBSCRIPTION_URL}`,
//   // options: {
//   //   reconnect: true,
//   // },
//   webSocketImpl: ws,
// }));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLinkSubscription,
  httpLink
);

export const client = new ApolloClient({
  cache: adminCache,
  link: ApolloLink.from([ErrorLink, authLink.concat(splitLink)]),
});

interface RequestUrlProps {
  reqUrl?: string;
}

const App = ({ reqUrl }: RequestUrlProps) => {


  const { AUTH_TOKEN, EVENT_SLUG } = LOCAL_STORAGE_KEY;

  let authToken = getLocalStorageItem(AUTH_TOKEN);
  let eventSlug = getLocalStorageItem(EVENT_SLUG);

  const params :any= new URLSearchParams(typeof window !== 'undefined' ? window?.location?.search : '')
  const token = params.get("token")
  if (token) {
    authToken = token as string | null;
    eventSlug =
      typeof window !== 'undefined'
        ? window.location.pathname.split('/')[1]
        : '';
    setLocalStorageItem(EVENT_SLUG, decodeURI(eventSlug));
    setLocalStorageItem(AUTH_TOKEN, authToken || '');
  }
  // const params: QueryString.ParsedQuery = QueryString.parse(
  //   typeof window !== 'undefined' ? window?.location?.search : ''
  // );

  // if (params.token) {
  //   authToken = params.token as string | null;
  //   eventSlug =
  //     typeof window !== 'undefined'
  //       ? window.location.pathname.split('/')[1]
  //       : '';
  //   setLocalStorageItem(EVENT_SLUG, decodeURI(eventSlug));
  //   setLocalStorageItem(AUTH_TOKEN, authToken || '');
  // }

  const [showVideo, setShowVideo] = useState<boolean>(false);
  let context = {};
 
  const routes = authToken ? (
    <AppRoutes setShowVideo={setShowVideo} showVideo={showVideo} />
  ) : (

    <AuthRoutes />
  );
  return (
    <>
    {/* {console.log("hisTory" , history)} */}


      {authToken && (
        <FloatingVideo setShowVideo={setShowVideo} showVideo={showVideo} />
      )}
      <ApolloProvider client={client}>
        <div className='App'>
          {process.env.BUILD_TARGET === 'server' ? (
            <StaticRouter  location={reqUrl!}>
              {routes}
            </StaticRouter>
          ) : (
            <Router>{routes}</Router>
          )}
        </div>
      </ApolloProvider>
    </>
  );
};

export default App;