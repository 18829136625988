import React from 'react';
import { ErrorMessage } from 'formik';
import { Typography } from 'antd';
import "./formStyle.scss";

interface Props {
    name: string;
    className?: string;
}

const { Text } = Typography;

const MyErrorMessage = (props: Props) => {
    const { name, className } = props;
    return (
        <>
            <Text type="danger" className={className}>
                <ErrorMessage  name= {name || '' } component="span" />
            </Text>
        </>
    );
};
export default MyErrorMessage;