import React from 'react'

import { images } from 'components/utility/image'
import { WSInput } from 'components/common/WSinput/WSInput'
import {
  FieldName,
  FieldType,
  EXTRA_ROUTE_NAME,
  LOCAL_STORAGE_KEY,
} from 'components/utility/enums'
import { WSSelect } from 'components/formComponent'
import { WSPassword } from 'components/common/WSpassword'
//features svg components
import OverviewMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/OverviewSVG'
import AnnouncementMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/AnnouncementSVG'
import MainSessionMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/MainSessionSVG'
import BreakOutSessionMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/BreakOutSessionSvg'
import SponsorMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/SponsorSVG'
import NetworkingMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/NetworkingSvg'
import VIPContentMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/VIPContentSVG'
import VirtualExpoMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/VirtualExpoSVG'
import PresenterMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/PresenterSVG'
import Schedule1On1MemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/Schedule1On1SVG'
import LivePolls_QAMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/LivePolls_QASVG'
import RecordingsMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/RecordingsSVG'
import SlidesMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/SlidesSVG'
import HelpDeskMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/HelpDeskSVG'
import SurveyMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/SurveySVG'
import AppGuideMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/AppGuideSVG'
import Q_A_WithSponsorMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/Q_A_WithSponsorSVG'
import BookMeetingsMemoSvgComponent from 'assets/images/sidebar/sidebar-svg-ts/BookMeetingsSVG'

import { FEATURES_NAME } from 'components/utility/enums'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'utils/helper'

export const VipContentDetailBookTimeData = [
  {
    key: '1',
    name: '1-on-1 with Anna Kavanagh',
    message: 'Video Calls and Limited Coaching Sessions Available',
    imagesPath: images.user5,
  },
  {
    key: '2',
    name: '1-on-1 with Sherry Brown',
    message: 'Video Calls and Limited Coaching Sessions Available',
    imagesPath: images.user2,
  },
  {
    key: '3',
    name: '1-on-1 with Maxwell Baum',
    message: 'Video Calls and Limited Coaching Sessions Available',
    imagesPath: images.user1,
  },
]

export const sortByOptionList = [
  { key: '1', value: 'First Name', disable: false },
  { key: '3', value: 'Last Name', disable: false },
  { key: '4', value: 'Title', disable: false },
  { key: '4', value: 'Company', disable: false },
]

export const optionList = [
  { id: 1, value: 'Consectetur', disable: false },
  { id: 2, value: 'Lorem', disable: false },
  { id: 3, value: 'Neqnlorem', disable: false },
]

export const layoutSidebar = [
  {
    key: '1',
    img: OverviewMemoSvgComponent,
    activeImg: images.IconOverViewSelect,
    link: EXTRA_ROUTE_NAME.OVERVIEW,
    name: FEATURES_NAME.OVERVIEW,
    routesArray: [EXTRA_ROUTE_NAME.OVERVIEW],
    selected: true,
  },
  {
    key: '2',
    img: NetworkingMemoSvgComponent,
    activeImg: images.IconNetworkingSelect,
    link: EXTRA_ROUTE_NAME.NETWORKING,
    name: FEATURES_NAME.NETWORKING,
    routesArray: [EXTRA_ROUTE_NAME.NETWORKING, EXTRA_ROUTE_NAME.USER_DETAIL],
    selected: false,
  },
  {
    key: '3',
    img: MainSessionMemoSvgComponent,
    activeImg: images.IconLiveStreamSelect,
    link: EXTRA_ROUTE_NAME.JOIN_LIVE_STREAM,
    name: FEATURES_NAME.MAIN_SESSION,
    routesArray: [
      EXTRA_ROUTE_NAME.JOIN_LIVE_STREAM,
      EXTRA_ROUTE_NAME.LIVE_STREAM,
    ],
    selected: false,
  },
  {
    key: '5',
    img: SponsorMemoSvgComponent,
    activeImg: images.IconSponsorsSelect,
    link: EXTRA_ROUTE_NAME.SPONSORS,
    name: FEATURES_NAME.SPONSORS,
    routesArray: [
      EXTRA_ROUTE_NAME.SPONSORS,
      EXTRA_ROUTE_NAME.SPONSORS_DETAIL,
      EXTRA_ROUTE_NAME.SPONSORS_SCHEDULE_MEETING_DETAIL,
    ],
    selected: false,
  },
  {
    key: '6',
    img: BreakOutSessionMemoSvgComponent,
    activeImg: images.IconScheduleSelect,
    link: EXTRA_ROUTE_NAME.SCHEDULE,
    name: FEATURES_NAME.EVENT_SESSIONS,
    routesArray: [EXTRA_ROUTE_NAME.SCHEDULE],
    selected: false,
  },
  {
    key: '7',
    img: AnnouncementMemoSvgComponent,
    activeImg: images.IconAnnouncementSelect,
    link: EXTRA_ROUTE_NAME.ANNOUNCEMENTS,
    name: FEATURES_NAME.ANNOUNCEMENTS,
    routesArray: [EXTRA_ROUTE_NAME.ANNOUNCEMENTS],
    selected: false,
  },
  {
    key: '8',
    img: PresenterMemoSvgComponent,
    activeImg: images.IconPresentersSelect,
    link: EXTRA_ROUTE_NAME.PRESENTERS,
    name: FEATURES_NAME.PRESENTERS,
    routesArray: [
      EXTRA_ROUTE_NAME.PRESENTERS,
      EXTRA_ROUTE_NAME.PRESENTERS_DETAIL,
      EXTRA_ROUTE_NAME.PRESENTERS_SCHEDULE_MEETING_DETAIL,
    ],
    selected: false,
  },
  {
    key: '9',
    img: LivePolls_QAMemoSvgComponent,
    activeImg: images.IconLivePollsQASelect,
    link: EXTRA_ROUTE_NAME.LIVE_POLLS,
    name: FEATURES_NAME.LIVE_POLLS_QA,
    routesArray: [EXTRA_ROUTE_NAME.LIVE_POLLS],
    selected: false,
  },
  {
    key: '10',
    img: RecordingsMemoSvgComponent,
    activeImg: images.IconRecordingsSelect,
    link: EXTRA_ROUTE_NAME.RECORDINGS,
    name: FEATURES_NAME.RECORDINGS,
    routesArray: [
      EXTRA_ROUTE_NAME.RECORDINGS,
      EXTRA_ROUTE_NAME.RECORDINGS_DETAIL,
    ],
    selected: false,
  },
  {
    key: '11',
    img: SlidesMemoSvgComponent,
    activeImg: images.IconSlidesSelect,
    link: EXTRA_ROUTE_NAME.SLIDES,
    name: FEATURES_NAME.SLIDES,
    routesArray: [EXTRA_ROUTE_NAME.SLIDES],
    selected: false,
  },
  // {
  //   key: '12',
  //   img: VirtualExpoMemoSvgComponent,
  //   activeImg: images.IconVirtualExpoSelect,
  //   link: EXTRA_ROUTE_NAME.VIRTUAL_EXPO,
  //   name: FEATURES_NAME.VIRTUAL_EXPO,
  //   routesArray: [EXTRA_ROUTE_NAME.VIRTUAL_EXPO],
  //   selected: false,
  // },
  {
    key: '13',
    img: HelpDeskMemoSvgComponent,
    activeImg: images.IconHelpDeskSelect,
    link: EXTRA_ROUTE_NAME.HELPDESK,
    name: FEATURES_NAME.HELP_DESK,
    routesArray: [EXTRA_ROUTE_NAME.HELPDESK],
    selected: false,
  },
  {
    key: '14',
    img: SurveyMemoSvgComponent,
    activeImg: images.IconSurveySelect,
    link: EXTRA_ROUTE_NAME.SURVEY,
    name: FEATURES_NAME.SURVEY,
    routesArray: [EXTRA_ROUTE_NAME.SURVEY],
    selected: false,
  },
  {
    key: '15',
    img: AppGuideMemoSvgComponent,
    activeImg: images.IconAppGuideSelect,
    link: EXTRA_ROUTE_NAME.APPGUIDE,
    name: FEATURES_NAME.APP_GUIDE,
    routesArray: [EXTRA_ROUTE_NAME.APPGUIDE, EXTRA_ROUTE_NAME.APPGUIDE_DETAIL],
    selected: false,
  },
  {
    key: '16',
    img: VIPContentMemoSvgComponent,
    activeImg: images.IconVIPContentSelect,
    link: EXTRA_ROUTE_NAME.VIP_CONTENT,
    name: FEATURES_NAME.VIP_CONTENT,
    routesArray: [
      EXTRA_ROUTE_NAME.VIP_CONTENT,
      EXTRA_ROUTE_NAME.VIP_CONTENT_DETAIL,
      EXTRA_ROUTE_NAME.VIP_CONTENT_BOOK_TIME,
    ],
    selected: false,
  },
  {
    key: '18',
    img: Q_A_WithSponsorMemoSvgComponent,
    activeImg: images.IconAMASessionsSelect,
    link: EXTRA_ROUTE_NAME.AMA_SESSION,
    name: FEATURES_NAME.QA_WITH_SPONSOR,
    routesArray: [
      EXTRA_ROUTE_NAME.AMA_SESSION,
      EXTRA_ROUTE_NAME.AMA_USER_SESSION,
    ],
    selected: false,
  },
  // {
  //   key: '19',
  //   img: BookMeetingsMemoSvgComponent,
  //   activeImg: images.IconBookMeetingsSelect,
  //   link: EXTRA_ROUTE_NAME.BOOK_MEETINGS,
  //   name: FEATURES_NAME.BOOK_MEETINGS,
  //   routesArray: [EXTRA_ROUTE_NAME.BOOK_MEETINGS],
  //   selected: false,
  // },
  // {
  //   key: '20',
  //   img: Schedule1On1MemoSvgComponent,
  //   activeImg: images.IconSchedule1v1Select,
  //   link: EXTRA_ROUTE_NAME.SCHEDULE_1V1,
  //   name: FEATURES_NAME.SCHEDULE1_ON_1,
  //   routesArray: [EXTRA_ROUTE_NAME.SCHEDULE_1V1],
  //   selected: false,
  // },
]

export const loginInputData = [
  {
    key: '1',
    type: FieldType.email,
    name: FieldName.email,
    label: 'Email',
    component: WSInput,
  },
  {
    key: '2',
    type: FieldType.password,
    name: FieldName.password,
    label: 'Password',
    component: WSPassword,
  },
]

export const forgotPasswordData = [
  {
    key: '1',
    type: FieldType.email,
    name: FieldName.email,
    label: 'Email',
    component: WSInput,
  },
]

export const changePasswordData = [
  {
    key: '1',
    name: FieldName.currentPassword,
    label: 'Current Password',
    type: FieldType.password,
    component: WSPassword,
  },
  {
    key: '2',
    name: FieldName.newPassword,
    label: 'New Password',
    type: FieldType.password,
    component: WSPassword,
  },
  {
    key: '3',
    name: FieldName.confirmPassword,
    label: 'Confirm Password',
    type: FieldType.password,
    component: WSPassword,
  },
]

export const setNewPasswordData = [
  {
    key: '1',
    name: FieldName.password,
    label: 'New Password',
    type: FieldType.password,
    component: WSPassword,
  },
  {
    key: '2',
    name: FieldName.confirmPassword,
    label: 'Confirm Password',
    type: FieldType.password,
    component: WSPassword,
  },
]

export const registerInputData = [
  {
    key: '1',
    type: FieldType.text,
    name: FieldName.name,
    label: 'Name',
    component: WSInput,
  },
  {
    key: '2',
    type: FieldType.email,
    name: FieldName.email,
    label: 'Email',
    component: WSInput,
  },
  {
    key: '3',
    type: FieldType.text,
    name: FieldName.companyName,
    label: 'Company Name',
    component: WSInput,
  },
  {
    key: '4',
    type: FieldType.select,
    name: FieldName.designation,
    label: 'Position in Company',
    component: WSSelect,
  },
]

export const NO_SMALL_VIDEO_ROUTES = [
  EXTRA_ROUTE_NAME.RECORDINGS_DETAIL,
  EXTRA_ROUTE_NAME.LIVE_STREAM,
  EXTRA_ROUTE_NAME.JOIN_LIVE_STREAM,
  EXTRA_ROUTE_NAME.JOIN_BREAKOUT_SESSION,
]

export const CommonVideoProps = {
  playing: true,
  controls: true,
  loop: false,
  width: '100%',
}

export const VideoPropsWithPlayPause = () => {
  const { VIDEO_REDIRECT_BACK, PLAYED_SEC, EVENT_SLUG } = LOCAL_STORAGE_KEY
  const eventSlug = getLocalStorageItem(EVENT_SLUG)
  const splitPath = window.location.pathname.split('/')
  const pathCondition = NO_SMALL_VIDEO_ROUTES.includes(splitPath[splitPath.length - 1])
  const storePath = pathCondition ?
    splitPath[splitPath.length - 1] === EXTRA_ROUTE_NAME.LIVE_STREAM
      ? `/${eventSlug}/${EXTRA_ROUTE_NAME.JOIN_LIVE_STREAM}`
      : window.location.pathname : ""
  return {
    ...CommonVideoProps,
    onPlay: () => {
      storePath && setLocalStorageItem(VIDEO_REDIRECT_BACK, storePath)
    },
    onPause: () => {
      removeLocalStorageItem(VIDEO_REDIRECT_BACK)
      removeLocalStorageItem(PLAYED_SEC)
    },
  }
}