import React from "react";
import { Select } from "antd";
import "./formStyle.scss";
import { Field, FieldProps } from "formik";
import { WSErrorMessage } from ".";
import { objectToSingleDataArray } from "utils/helper";

const { Option } = Select;

interface Props {
  placeHolder?: string;
  optionList?: any;
  className?: string;
  name: string;
  mode?: "multiple" | "tags" | undefined;
  value?: string;
  label?: string;
  listOptions?: string;
  passed?: string;
}

const WSSelect = (props: Props) => {
  const {
    optionList,
    placeHolder,
    className,
    name,
    mode,
    label,
    listOptions = "name",
    passed = "_id",
  } = props;
  const renderOptions = objectToSingleDataArray(optionList, listOptions, passed);
  return (
    <div className="d-flex flex-column">
      {label && <label>{label}</label>}
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Select
            mode={mode}
            value={field.value}
            placeholder={placeHolder}
            className={`${className} main-select-section`}
            onChange={(e) => form.setFieldValue(name, e)}
            onBlur={() => form.setFieldTouched(name, true)}
          >
            {renderOptions &&
              renderOptions.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
          </Select>
        )}
      </Field>
      <WSErrorMessage name={name} />
    </div>
  );
};

export default WSSelect;
