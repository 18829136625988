import React from "react";
import { Image as AntImage } from "antd";
import { ImageProps } from "rc-image/lib/Image";
import { images } from "components/utility/image";
import { Common_Name } from "components/utility/enums";
export const WSImage: React.FC<ImageProps> = ({ ...props }) => {
  const { src,fallback } = props;
  const { emptyImage } = images;
  const { ERROR } = Common_Name;
  return (
    <AntImage
      {...props}
      src={src || ERROR}
      fallback={fallback || emptyImage}
      preview={false}
    />
  );
};
