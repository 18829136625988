const RouterPath = {
  // Authentication
  VerifyLogin: "/:id",
  LoginScreen: "/:id/auth/",
  RegisterScreen: "/:id/auth/register/",
  ForgotPassword: "/forgot-password/:id",
  ForgotPasswordEmail: "/:id/auth/forgot-password-email",
  SetPassword: "/set-password/:id",
  ChangePassword: "/:id/change-password",
  VerifyRegister: "/:id/auth/verify-register",
  InstructionSent: "/:id/auth/instruction-sent",
  SetPasswordSuccess: "/auth/success-password",

  CheckOverview: "/check-overview",
  Overview: "/:id/overview",
  VirtualExpo: "/:id/virtual-expo",
  Networking: "/:id/networking",
  UserDetail: "/:id/user-detail",
  LiveStream: "/:id/live-stream",
  JoinLiveStream: "/:id/join-live-stream",
  LivePolls: "/:id/live-polls",
  Survey: "/:id/survey",
  BreakoutSession: "/:id/breakout-session",
  JoinBreakoutSession: "/:id/join-breakout-session",
  Sponsors: "/:id/sponsers",
  SponsorsDetail: "/:id/sponsers-detail",
  SponsorsScheduleMeetingDetail: "/:id/schedule-meeting-detail",
  PresentersScheduleMeetingDetail: "/:id/presenter-meeting-detail",
  Schedule: "/:id/schedule",
  Announcements: "/:id/announcements",
  Presenters: "/:id/presenters",
  PresentersDetail: "/:id/presenters-detail",
  Recordings: "/:id/recordings",
  RecordingsDetail: "/:id/recordings-detail",
  AppGuideDetail: "/:id/app-guide-detail",
  HelpDesk: "/:id/help-desk",
  Slides: "/:id/slides",
  AppGuide: "/:id/app-guide",
  VipContent: "/:id/vip-content",
  VipContentDetail: "/:id/vip-content-detail",
  StreamOptions: "/:id/stream-options",
  StreamOptionDetail: "/:id/stream-option-detail",
  SearchPage: "/:id/search-page",
  VipContentBookTime: "/:id/vip-content-book-time-detail",
  AmaSessions: "/:id/ama-sessions",
  AMAUserSession: "/:id/ama-user-session",
  BookMeetings: "/:id/book-meetings",
  Schedule1v1: "/:id/schedule-1v1",
  Profile: "/:id/profile",
};

export default RouterPath;