import React from "react";
import "./WSTitle.scss";
import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";

const { Title } = Typography;

export const title = Title;

export const WSTitle: React.FC<TitleProps> = ({ children, ...props }) => {

  return <div className="ws-title"><Title {...props}>{children}</Title></div>;
};
