import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function PresenterSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      data-name='Group 283'
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}
    >
      <path
        data-name='Path 2115'
        d='M19.219 7.587A.781.781 0 0020 6.806V3.125A3.128 3.128 0 0016.875 0H3.125A3.128 3.128 0 000 3.125V10.7a3.128 3.128 0 003.125 3.125h6.094v4.609H7.266a.781.781 0 000 1.563h5.469a.781.781 0 000-1.562h-1.954v-4.607h6.094A3.128 3.128 0 0020 10.7a.781.781 0 00-1.562 0 1.564 1.564 0 01-1.562 1.563h-.781v-.781a.781.781 0 00-1.562 0v.781h-1.564v-.781a.781.781 0 00-1.562 0v.781H8.594v-.781a.781.781 0 00-1.562 0v.781H5.469v-.781a.781.781 0 00-1.562 0v.781h-.782A1.564 1.564 0 011.563 10.7V3.125a1.564 1.564 0 011.562-1.562h13.75a1.564 1.564 0 011.563 1.563v3.68a.781.781 0 00.781.781z'
        fill={props.outercolor}
      />
      <path
        data-name='Path 2116'
        d='M14.961 4.672v3.316h-10V4.672h10m0-1.172h-10a1.281 1.281 0 00-1.367 1.172v3.316A1.281 1.281 0 004.961 9.16h10a1.281 1.281 0 001.367-1.172V4.672A1.281 1.281 0 0014.961 3.5z'
        fill={props.innercolor}
      />
    </svg>
  )
}

const PresenterMemoSvgComponent = React.memo(PresenterSvgComponent)
export default PresenterMemoSvgComponent
