import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function BreakOutSessionSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      data-name='Group 278'
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}
    >
      <path
        data-name='Path 2110'
        d='M9 14.293a1.642 1.642 0 001.575 1.7h3.85a1.642 1.642 0 001.575-1.7v-2a1.642 1.642 0 00-1.575-1.7h-3.85A1.642 1.642 0 009 12.298zm1.575-2h3.85v2h-3.851z'
        fill={props.innercolor}
      />
      <circle
        data-name='Ellipse 31'
        cx={1}
        cy={1}
        r={1}
        transform='translate(13.982 6.991)'
        fill={props.outercolor}
      />
      <path
        data-name='Path 2111'
        d='M19.219 13.75a.781.781 0 00.781-.781V4.688a3.129 3.129 0 00-3.125-3.125h-1.016V.781a.781.781 0 00-1.562 0v.781h-3.555V.781a.781.781 0 00-1.562 0v.781H5.664V.781a.781.781 0 00-1.564 0v.781h-.975A3.129 3.129 0 000 4.688v12.187A3.129 3.129 0 003.125 20h13.75A3.129 3.129 0 0020 16.875a.781.781 0 00-1.562 0 1.564 1.564 0 01-1.562 1.563H3.125a1.564 1.564 0 01-1.562-1.562V4.688a1.564 1.564 0 011.562-1.563H4.1v.781a.781.781 0 001.563 0v-.781H9.18v.781a.781.781 0 001.563 0v-.781H14.3v.781a.781.781 0 001.563 0v-.781h1.016a1.564 1.564 0 011.563 1.563v8.281a.781.781 0 00.777.781z'
        fill={props.outercolor}
      />
      <circle
        data-name='Ellipse 34'
        cx={1}
        cy={1}
        r={1}
        transform='translate(3.995 6.991)'
        fill={props.outercolor}
      />
      <circle
        data-name='Ellipse 39'
        cx={1}
        cy={1}
        r={1}
        transform='translate(3.995 10.49)'
        fill={props.outercolor}
      />
      <circle
        data-name='Ellipse 41'
        cx={1}
        cy={1}
        r={1}
        transform='translate(3.995 13.991)'
        fill={props.outercolor}
      />
      <circle
        data-name='Ellipse 37'
        cx={1}
        cy={1}
        r={1}
        transform='translate(8.99 6.991)'
        fill={props.outercolor}
      />
    </svg>
  )
}

const BreakOutSessionMemoSvgComponent = React.memo(BreakOutSessionSvgComponent)
export default BreakOutSessionMemoSvgComponent
