import React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import { WSImage } from "../WSImage";
import "./WSButton.scss";

interface CustomButtonProps extends ButtonProps {
  imgSrc?: string;
  imgWidth?: string;
  leftImgWidth?: string;
  count?: number;
  leftIcon?: React.ReactNode;
  leftImgSrc?: string;
}

export const WSButton: React.FC<CustomButtonProps> = ({
  children,
  imgSrc,
  imgWidth,
  leftImgWidth,
  count,
  leftIcon,
  leftImgSrc,
  ...props
}) => {
  return (
    <div className='ws-button'>
      <Button {...props}>
        {leftImgSrc && (
          <WSImage
            className='left-button-image'
            src={leftImgSrc}
            width={leftImgWidth}
          />
        )}
        {leftIcon && <div className='left-icon'>{leftIcon}</div>}
        {children}
        {count && count > 0 ? <span className='ml-1'>({count})</span> : null}
        {imgSrc && <WSImage src={imgSrc} width={imgWidth} />}
      </Button>
    </div>
  )
};
