import { images } from "./image";

export const TabType: any = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
};

export const Common_Type = {
  TRUE: 'true',
  NAME: 'name'
};

export const LOCAL_STORAGE_KEY = {
  AUTH_TOKEN: "auth_token",
  USER_INFO: "user_info",
  USER_ACCESS: "user_access",
  EVENT_SLUG: "eventSlug",
  EVENT_CODE: "event_code",
  EVENT_DATA: "eventData",
  APP_URL: "app_url",
  PLAYED_SEC: "played-sec",
  VIDEO_REDIRECT_BACK: "redirect-back",
  VIDEO_DATA: "video-data",
};

export const FEATURES_NAME = {
  OVERVIEW: "Overview",
  NETWORKING: "Networking",
  SPONSORS: "Sponsors",
  EVENT_SESSIONS: "Breakout Sessions",
  ANNOUNCEMENTS: "Announcements",
  SCHEDULE1_ON_1: "Schedule 1-On-1",
  SLIDES: "Slides",
  VIRTUAL_EXPO: "Virtual Expo",
  HELP_DESK: "Help Desk",
  PRESENTERS: "Presenters",
  VIP_CONTENT: "VIP Content",
  RECORDINGS: "Recordings",
  SURVEY: "Survey",
  APP_GUIDE: "App Guide",
  MAIN_SESSION: "Main Session",
  BREAKOUT_SESSION: "Breakout Session",
  STREAM_OPTIONS: "Stream Options",
  QA_WITH_SPONSOR: "Q&A with Sponsor",
  BOOK_MEETINGS: "Book Meetings",
  LIVE_POLLS_QA: "Live Polls",
  CATEGORY: 'Category'
}

export const NOTIFICATION_ENUM = {
  ASK_US_ANYTHING: 'Q&A with Sponsor',
  LIVE_POLLS: 'Live Polls',
}
export const EXTRA_ROUTE_NAME = {
  FORGOT_PASSWORD: "forgot-password",
  FORGOT_PASSWORD_EMAIL: "forgot-password-email",
  VERIFY_REGISTER: "verify-register",
  INSTRUCTION_SENT: "instruction-sent",
  REGISTER: "register",
  TERMS_CONDITIONS: "terms-condition",
  OVERVIEW: "overview",
  BOOK_MEETINGS: "book-meetings",
  VIRTUAL_EXPO: "virtual-expo",
  NETWORKING: "networking",
  USER_DETAIL: "user-detail",
  LIVE_STREAM: "live-stream",
  JOIN_LIVE_STREAM: "join-live-stream",
  LIVE_POLLS: "live-polls",
  SURVEY: "survey",
  BREAKOUT_SESSION: "breakout-session",
  JOIN_BREAKOUT_SESSION: "join-breakout-session",
  SPONSORS: "sponsers",
  SPONSORS_DETAIL: "sponsers-detail",
  SPONSORS_SCHEDULE_MEETING_DETAIL: "schedule-meeting-detail",
  PRESENTERS_SCHEDULE_MEETING_DETAIL: "presenter-meeting-detail",
  SCHEDULE: "schedule",
  ANNOUNCEMENTS: "announcements",
  PRESENTERS: "presenters",
  PRESENTERS_DETAIL: "presenters-detail",
  RECORDINGS: "recordings",
  RECORDINGS_DETAIL: "recordings-detail",
  APPGUIDE_DETAIL: "app-guide-detail",
  HELPDESK: "help-desk",
  SLIDES: "slides",
  APPGUIDE: "app-guide",
  VIP_CONTENT: "vip-content",
  VIP_CONTENT_DETAIL: "vip-content-detail",
  STREAM_OPTIONS: "stream-options",
  STREAM_OPTION_DETAIL: "stream-option-detail",
  SEARCH_PAGE: "search-page",
  VIP_CONTENT_BOOK_TIME: "vip-content-book-time-detail",
  AMA_SESSION: "ama-sessions",
  AMA_USER_SESSION: "ama-user-session",
  SCHEDULE_1V1: "schedule-1v1",
  PROFILE: "profile",
  CHANGE_PASSWORD: "change-password",
};

export const MOMENT_TYPE: {
  DAY:
  | 'year'
  | 'years'
  | 'y'
  | 'month'
  | 'months'
  | 'M'
  | 'week'
  | 'weeks'
  | 'w'
  | 'day'
  | 'days'
  | 'd'
} = {
  DAY: 'day',
}

export const DATE_FORMATE = {
  YYYY_MM_DD: "YYYY-MM-DD",
  MMM_DD_YYYY: "MMM DD, YYYY",
  YYYY_MM_DD_hh_mm_a: "YYYY-MM-DD hh:mm a",
  hh_mm_a: "hh:mm a",
}

export const EVENT_TYPE = {
  PUBLIC: "Public",
  INVITE_ONLY: "Invite Only",
  EVENT_CODE: "Event Code",
  UNIQUE_EVENT_CODE: "Unique Event Code",
  IN_PERSON: 'In Person',
  VIRTUAL: 'Virtual',
};

export const FieldType = {
  text: "text",
  password: "password",
  email: "email",
  search: "search",
  number: "number",
  url: "url",
  file: "file",
  date: "date",
  select: "select",
};

export const FieldName = {
  skills: "skills",
  designation: "designation",
  name: "name",
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  currentPassword: "currentPassword",
  newPassword: "newPassword",
  companyName: "companyName",
};

export const LOOKUP_TYPE = {
  skills: "user_skills",
  designation: "user_designation",
};

export const BUTTON_LABELS = {
  JOIN_MAIN_SESSION: "Join Session",
  VIEW_RECORDING: "View Recorded",
  REMOVE: "Remove",
  APPROVAL_PENDING: "Approval Pending",
  REQUEST_ACCESS: "Request Access",
};
export const Document_Type = {
  DOC: "DOC",
  PDF: "PDF",
  WORD: "WORD",
};

export const Common_Name: any = {
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  TITLE: "title",
  COMPANY: "company",
  NOTE: "Note",
  ERROR: "error"
};

export const SELECT_FEATURE = {
  Recordings: "Recordings",
  eventSessions: "Breakout Sessions",
  AppGuide: "App Guide",
  Presenters: "Presenters",
  Networking: "Networking",
  Sponsors: "Sponsors",
};

export const SINGLE_SCHEMA: any = {
  Networking: "getSingleFeatureNetworking",
  Presenters: "getPresenter",
  Sponsors: "getSingleFeatureSponsor",
};

export const PROVIDERS = {
  Twitch: "Twitch",
  Vimeo: "Vimeo",
  YouTube: "YouTube",
  SoundCloud: "SoundCloud",
  Facebook: "Facebook",
  DailyMotion: "DailyMotion",
  Wistia: "Wistia",
  Streamable: "Streamable",
  Zoom: "Zoom",
  ForRecordingPage: "ForRecording",
};
export const PROVIDER_LIST = [
  { name: "Twitch", image: images.twitchProvider },
  { name: "Vimeo", image: images.vimeoProvider },
  { name: "YouTube", image: images.youtubeProvider },
  { name: "SoundCloud", image: images.soundCloudProvider },
  { name: "Facebook", image: images.facebookProvider },
  { name: "DailyMotion", image: images.dailymotionProvider },
  { name: "Wistia", image: images.wistiaProvider },
  { name: "Streamable", image: images.streamableProvider },
  { name: "Zoom", image: images.zoomProvider },
];

export const SOCIAL_MEDIA_LIST = [
  { name: "LinkedIn", image: images.linkedin },
  { name: "Twitter", image: images.twitter },
  { name: "Facebook", image: images.facebook },
  { name: "Other", image: images.website },
];

export const LINK_TYPE = {
  WEBSITE: "Website",
  PHONE: "Phone",
  EMAIL: "Email",
};

export const GRAPHQL_ERROR_MESSAGE = {
  UNAUTHENTICATED: "UNAUTHENTICATED",
  JWT_EXPIRED: "jwt expired",
  JWT_MALFORMED: "jwt malformed",
  UNAUTHORIZED: "Unauthorized",
  TOKEN_NOT_VALID: "Token not valid",
  WRONG_EVENT_CODE: "given code does not match with record",
  ACCESS_ERROR: "You have no access for this event",
};

export const ButtonNames = {
  Cancel: "Cancel",
  Edit_Response: "Edit Response",
  Send: "Send",
};

export const MEDIA_TYPE: any = {
  image: "IMAGE",
  application: "DOCUMENT",
};


export const NetworkingSortBy = [
  {
    name: Common_Name.FIRSTNAME,
  },
  {
    name: Common_Name.LASTNAME,
  },
  {
    name: Common_Name.TITLE,
  },
  {
    name: Common_Name.COMPANY,
  },
];

export const Regex = {
  RGB: '/^rgb/',
  ColorMatch: '/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/'
}

export enum THEME_TYPE {
  light = 'light',
  dark = 'dark',
}

export const VIDEO_CLASS_NAME: { [name: string]: { className: string } } = {
  'YouTube': { className: 'ws-video' },
  'Vimeo': { className: 'vimeo-video' },
  'Wistia': { className: 'wistia-video' },
  'Facebook': { className: 'facebook-video' }
}

export const ROLE = {
  SYSTEM_MANAGER: 'system-manager',
  ADMIN: 'admin',
  USER: 'user'
}

export const HAS_REPLY_ROLE = [
  'system-manager',
  'admin',
  
]

export const MEDIA_TYPE_ENUM: Array<string> = [
  'IMAGE',
  'VIDEO',
  'DOCUMENT'
]

export const MEDIA_NAME_ENUM: Array<string> = [
  'USERIMAGE',
  'FEATUREIMAGE',
  'EVENT_TYPE_ICON',
  'ATTENDEE_IMAGE',
  'ANNOUNCEMENT_IMAGE',
  'SPEAKER_ATTACHMENTS',
  'SPEAKER_ICON',
  'EXHIBITOR_ATTACHMENTS',
  'EXHIBITOR_ICON',
  'SINGLE_DOCUMENT_FILES',
  'DOCUMENT_FILE',
  'MAP_IMAGE',
  'WEBLIST_ICON',
  'SINGLE_ITEM_ATTACHMENTS',
  'SINGLE_ITEM_ICON',
  'FEATURE_SPONSORS_ITEM_IMAGE',
  'FEATURE_SPONSORS_ITEM_ICON',
  'FEATURE_SPONSORS_ITEM_ATTACHMENTS',
  'EVENT_IMAGE',
  'EVENT_ICON',
  'BANNER_IMAGE',
  'COMMUNITY_ICON',
  'APPLICATION_ICON',
  'WEB_SPONSOR_ICON',
  'WEB_RECORDING',
  'SLIDE_PDF',
  'QUESTION_ICON',
  'VIRTUAL_EXPO',
  'APP_GUIDE',
  'FEATURE_NETWORKING_IMAGE',
  'SCHEDULE_ATTACHMENTS',
  'PRESENTER_IMAGE',
  'STREAM_OPTION_IMAGE',
  'BOOKING_IMAGE',
  'BOOKING_ICON',
  'SCHEDULEv1_IMAGE',
  'LOOKUP_ICON',
  'EVENT_COVER',
  'EVENT_LOGO',
  'BOOK_METTING_ATTACHMENTS',
  'BOOK_METTING_ICON',
  'BOOK_METTING_IMAGE',
  'AMA_SESSION_IMAGE',
  'HELP_DESK_IMAGE',
  'HELP_DESK_ATTACHMENTS',
  'SCHEDULE_ATTENDEE_IMAGE',
  'APP_GUIDE_ATTACHMENTS',
  'ADMIN_RECORDING_ATTACHMENT',
  'SLIDE_ATTACHMENT',
  'QUESTION_IMAGE',
  'QUESTION_FILE',
  'COMMENT_IMAGE',
  'COMMENT_FILE',
  'APP_LOGO',
  'EVENT_SPONSOR_BANNER',
  'EVENT_LOGIN_BANNER',
  'QUESTION_REPLY_IMAGE',
  'QUESTION_REPLY_FILE',
]