export const images = {
  DashboardImage: require("assets/images/defaultBanner.jpg"),
  Logo: require("assets/images/logo.png"),
  UserProfilePhoto: require("assets/images/user.png"),
  user1: require("assets/images/networking/user1.png"),
  user2: require("assets/images/networking/user2.png"),
  user3: require("assets/images/networking/user3.png"),
  user4: require("assets/images/networking/user4.png"),
  user5: require("assets/images/networking/user5.png"),
  user6: require("assets/images/networking/user6.png"),
  user7: require("assets/images/networking/user7.png"),
  user8: require("assets/images/networking/user8.png"),
  user9: require("assets/images/networking/user9.png"),
  user10: require("assets/images/networking/user10.png"),
  user11: require("assets/images/networking/user11.png"),
  user12: require("assets/images/networking/user12.png"),
  user13: require("assets/images/networking/user13.png"),
  user14: require("assets/images/networking/user14.png"),
  user15: require("assets/images/networking/user15.png"),
  user16: require("assets/images/networking/user16.png"),
  user17: require("assets/images/networking/user17.png"),
  nextArrow: require("assets/images/networking/right-arrow.svg"),
  prevArrowBlack: require("assets/images/left-arrow-black.svg"),
  prevArrowWhite: require("assets/images/left-arrow-white.svg"),
  nextColorArrow: require("assets/images/right-color-arrow.svg"),
  star: require("assets/images/star.svg"),
  grayStar: require("assets/images/gray-star.svg"),
  outlineStar: require("assets/images/star-outline.svg"),
  note: require("assets/images/note.svg"),
  twitch: require("assets/images/sponsers/twitch.png"),
  google: require("assets/images/sponsers/google.png"),
  cisco: require("assets/images/sponsers/cisco.png"),
  netapp: require("assets/images/sponsers/netapp.png"),
  adobe: require("assets/images/sponsers/adobe.png"),
  autodesk: require("assets/images/sponsers/autodesk.png"),
  spotify: require("assets/images/sponsers/spotify.png"),
  asana: require("assets/images/sponsers/asana.png"),
  box: require("assets/images/sponsers/box.png"),
  location: require("assets/images/pin.svg"),
  user: require("assets/images/user.svg"),
  website: require("assets/images/website.svg"),
  phoneCall: require("assets/images/call.png"),
  email: require("assets/images/email.png"),
  linkedin: require("assets/images/linkedin.svg"),
  twitter: require("assets/images/twitter.svg"),
  facebook: require("assets/images/facebook.svg"),
  commonImage: require("assets/images/user.png"),
  announcement: require("assets/images/announcement/announcement.png"),
  comment: require("assets/images/helpDesk/Speech_Bubble.svg"),
  middleBanner: require("assets/images/helpDesk/middleimg.png"),
  circleIcon: require("assets/images/circle.svg"),
  IconBackgroundVIPContent: require("assets/images/Small Twitch Square.svg"),
  heartIcon: require("assets/images/sponsers/heart.svg"),
  nextIcon: require("assets/images/sponsers/next.svg"),
  forward: require("assets/images/forward.png"),
  forwardLeft: require("assets/images/forward_left.png"),
  playButton: require("assets/images/play-button.svg"),
  slides: require("assets/images/slides/slides.svg"),
  streamTwitch: require("assets/images/streamOptions/twitch.png"),
  streamBrightCove: require("assets/images/streamOptions/brightCove.png"),
  streamDacast: require("assets/images/streamOptions/dacast.png"),
  streamFacebookLive: require("assets/images/streamOptions/facebookLive.png"),
  streamGotoMeeting: require("assets/images/streamOptions/gotoMeeting.png"),
  streamPeriscope: require("assets/images/streamOptions/periscope.png"),
  streamVimeo: require("assets/images/streamOptions/vimeo.png"),
  streamWorkCast: require("assets/images/streamOptions/workCast.png"),
  streamYoutube: require("assets/images/streamOptions/youtube.png"),
  streamZoom: require("assets/images/streamOptions/zoom.png"),
  appGuideIcon: require("assets/images/app-guide.png"),
  vipContentStar: require("assets/images/vip-content-star.png"),
  recordingsIcon: require("assets/images/recordings/recordings-icon.png"),
  slidesPdf: require("assets/images/slides-pdf.png"),
  slidesDoc: require("assets/images/slides-doc.png"),
  slidesWord: require("assets/images/slides-word.png"),
  amaSessionsIcon: require("assets/images/amasessionsicon.png"),
  sortIcon: require("assets/images/sort-by.svg"),
  filterIcon: require("assets/images/filter.svg"),
  modalCloseIcon: require("assets/images/modal-close.svg"),
  addIcon: require("assets/images/add.svg"),
  whiteStarIcon: require("assets/images/star-white.svg"),
  whiteNoteIcon: require("assets/images/note-white.svg"),
  blackNoteIcon: require("assets/images/document.png"),
  appDownloadIcon: require("assets/images/download.svg"),
  emptyImage: require("assets/images/emptyImage.png"),
  searchCloseIcon: require("assets/images/search-close.svg"),
  blackClose: require("assets/images/close.svg"),
  thumbColor: require("assets/images/thumb-color.png"),
  thumbBlack: require("assets/images/thumb-black.png"),
  trophyColor: require("assets/images/trophy-color.png"),
  trophyBlack: require("assets/images/trophy-black.png"),
  correctColor: require("assets/images/correct-color.png"),
  correctBlack: require("assets/images/correct-black.png"),
  goBack: require("assets/images/return.svg"),
  overviewBanner: require("assets/images/overview/overview-banner.png"),
  recordingsDetails: require("assets/images/recordings/recordings.png"),
  defaultCover: require("assets/images/defaultCover.png"),
  defaultLogo: require("assets/images/defaultLogo.jpg"),

  // Auth
  loginBanner: require("assets/images/login/login_banner.png"),
  loginLogo: require("assets/images/login/EVE_Logo4.png"),
  authCorrect: require("assets/images/verify.jpg"),
  authLeftImg: require("assets/images/auth-left.png"),
  authRightImg: require("assets/images/auth-right.png"),

  // Icons --------------------------------------------------------------------------
  IconOverView: require("assets/images/sidebar/overview.png"),
  IconOverViewSelect: require("assets/images/sidebar/overview-select.png"),
  IconNetworking: require("assets/images/sidebar/networking.png"),
  IconNetworkingSelect: require("assets/images/sidebar/networking-select.png"),
  IconLiveStream: require("assets/images/sidebar/live-stream.png"),
  IconLiveStreamSelect: require("assets/images/sidebar/live-stream-select.png"),
  IconBreakoutSession: require("assets/images/sidebar/breakout-session.png"),
  IconBreakoutSessionSelect: require("assets/images/sidebar/breakout-session-select.png"),
  IconSponsors: require("assets/images/sidebar/sponsers.png"),
  IconSponsorsSelect: require("assets/images/sidebar/sponsers-select.png"),
  IconSchedule: require("assets/images/sidebar/schedule.png"),
  IconScheduleSelect: require("assets/images/sidebar/schedule-select.png"),
  IconAnnouncement: require("assets/images/sidebar/announcement.png"),
  IconAnnouncementSelect: require("assets/images/sidebar/announcement-select.png"),
  IconPresenters: require("assets/images/sidebar/presenters.png"),
  IconPresentersSelect: require("assets/images/sidebar/presenters-select.png"),
  IconLivePollsQA: require("assets/images/sidebar/live-polls.png"),
  IconLivePollsQASelect: require("assets/images/sidebar/live-polls-select.png"),
  IconRecordings: require("assets/images/sidebar/recordings.png"),
  IconRecordingsSelect: require("assets/images/sidebar/recordings-select.png"),
  IconSlides: require("assets/images/sidebar/slides.png"),
  IconSlidesSelect: require("assets/images/sidebar/slides-select.png"),
  IconVirtualExpo: require("assets/images/sidebar/virtual-expo.png"),
  IconVirtualExpoSelect: require("assets/images/sidebar/virtual-expo-select.png"),
  IconHelpDesk: require("assets/images/sidebar/help-desk.png"),
  IconHelpDeskSelect: require("assets/images/sidebar/help-desk-select.png"),
  IconSurvey: require("assets/images/sidebar/survey.png"),
  IconSurveySelect: require("assets/images/sidebar/survey-select.png"),
  IconAppGuide: require("assets/images/sidebar/app-guide.png"),
  IconAppGuideSelect: require("assets/images/sidebar/app-guide-select.png"),
  IconVIPContent: require("assets/images/sidebar/vip-content.png"),
  IconVIPContentSelect: require("assets/images/sidebar/vip-content-select.png"),
  IconStreamOptions: require("assets/images/sidebar/stream-options.png"),
  IconStreamOptionsSelect: require("assets/images/sidebar/stream-options-select.png"),
  IconAMASessions: require("assets/images/sidebar/ama-sessions.png"),
  IconAMASessionsSelect: require("assets/images/sidebar/ama-sessions-select.png"),
  IconBookMeetings: require("assets/images/sidebar/book-meetings.png"),
  IconBookMeetingsSelect: require("assets/images/sidebar/book-meetings-select.png"),
  IconSchedule1v1: require("assets/images/sidebar/schedule-1v1.png"),
  IconSchedule1v1Select: require("assets/images/sidebar/schedule-1v1-select.png"),
  IconClock: require("assets/images/clock.svg"),
  IconGPS: require("assets/images/icons/gps@2x.png"),
  IconEdit: require("assets/images/edit.svg"),
  IconUserBackground: require("assets/images/user-background.svg"),
  IconCalender: require("assets/images/icons/Group 871@2x.png"),
  IconLike: require("assets/images/icons/Path 3458@2x.png"),
  IconRaiseHand: require("assets/images/icons/Group 876@2x.png"),
  defaultUser: require("assets/images/defaultuser.webp"),
  Gallery: require("assets/images/image-gallery.png"),
  uploadImage: require("assets/images/helpDesk/image.svg"),
  uploadDocs: require("assets/images/helpDesk/files.svg"),
  closeIcon: require("assets/images/close.png"),
  // Videos -------------------------------------------------------------------------
  LiveStreamVideo: require("assets/videos/26523411.mp4"),

  //providers
  zoomProvider: require("assets/images/livestream/providers/providers-zoom.png"),
  streamableProvider: require("assets/images/livestream/providers/providers-streamable.png"),
  wistiaProvider: require("assets/images/livestream/providers/providers-wistia.png"),
  dailymotionProvider: require("assets/images/livestream/providers/providers-dailymotion.png"),
  facebookProvider: require("assets/images/livestream/providers/providers-facebook.png"),
  soundCloudProvider: require("assets/images/livestream/providers/providers-soundCloud.png"),
  vimeoProvider: require("assets/images/livestream/providers/providers-vimeo.png"),
  twitchProvider: require("assets/images/livestream/providers/providers-twitch.png"),
  youtubeProvider: require("assets/images/livestream/providers/providers-youtube.png"),
};
