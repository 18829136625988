import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function AnnouncementSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20.001}
      height={20}
      viewBox='0 0 20.001 20'
      {...props}
    >
      <g data-name='Group 282'>
        <g data-name='Group 280'>
          <g data-name='Group 279'>
            <path
              data-name='Path 2112'
              d='M19.22 11.014a.781.781 0 00.78-.781v-7.89A2.344 2.344 0 0016.868.136q-.033.012-.065.027l-6.259 2.9a10.465 10.465 0 01-4.365.975l-3.056.018A3.129 3.129 0 000 7.18v2.031a3.135 3.135 0 002.188 2.98v5.434a2.4 2.4 0 00.717 1.7 2.31 2.31 0 001.627.671h.045a2.344 2.344 0 002.3-2.343v-5.32a10.6 10.6 0 013.811 1.024l6.1 2.953a.787.787 0 00.074.031A2.344 2.344 0 0020 14.138a.781.781 0 10-1.563 0 .781.781 0 01-1.017.745l-6.055-2.933a12.179 12.179 0 00-4.492-1.184V5.58a12.006 12.006 0 004.327-1.1l6.222-2.881a.781.781 0 011.017.745v7.89a.781.781 0 00.781.78zM5.313 17.656a.785.785 0 01-.766.781.76.76 0 01-.547-.224.831.831 0 01-.249-.589v-6.075a.781.781 0 00-.729-.78 1.566 1.566 0 01-1.459-1.558V7.18a1.564 1.564 0 011.562-1.563l2.183-.013z'
              fill={props.outercolor}
            />
          </g>
        </g>
        <g data-name='Group 281'>
          <path
            data-name='Path 2114'
            d='M15.01 5.861a.286.286 0 01.284.285v4.3a.286.286 0 01-.284.285.286.286 0 01-.087-.014l-2.209-1.07a4.443 4.443 0 00-1.639-.432V7.321a4.381 4.381 0 001.578-.4l2.27-1.051a.287.287 0 01.087-.014m0-1.289a1.574 1.574 0 00-.477.074 1.288 1.288 0 00-.152.059l-2.27 1.051a3.1 3.1 0 01-1.116.283 1.289 1.289 0 00-1.21 1.287v1.894a1.289 1.289 0 001.2 1.286 3.165 3.165 0 011.162.306l2.209 1.07a1.285 1.285 0 00.171.068 1.574 1.574 0 002.051-1.5v-4.3a1.575 1.575 0 00-1.573-1.575z'
            fill={props.innercolor}
          />
        </g>
      </g>
    </svg>
  )
}

const AnnouncementMemoSvgComponent = React.memo(AnnouncementSvgComponent)
export default AnnouncementMemoSvgComponent
