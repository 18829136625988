import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function SlidesSvgComponent(props: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16.565}
      height={20}
      viewBox='0 0 16.565 20'
      {...props}
    >
      <g data-name='Group 294'>
        <g data-name='Group 287'>
          <g data-name='Group 286'>
            <path
              data-name='Path 2123'
              d='M12.64 4.214H7.008a.794.794 0 000 1.588h5.632a.794.794 0 100-1.588z'
              fill={props.innercolor}
            />
          </g>
        </g>
        <g data-name='Group 289'>
          <g data-name='Group 288'>
            <path
              data-name='Path 2124'
              d='M12.64 7.335H7.008a.794.794 0 000 1.588h5.632a.794.794 0 100-1.588z'
              fill={props.innercolor}
            />
          </g>
        </g>
        <g data-name='Group 291'>
          <g data-name='Group 290'>
            <path
              data-name='Path 2125'
              d='M9.474 10.456H7.053a.794.794 0 000 1.588h2.421a.794.794 0 000-1.588z'
              fill={props.innercolor}
            />
          </g>
        </g>
        <g data-name='Group 293'>
          <g data-name='Group 292'>
            <path
              data-name='Path 2126'
              d='M15.784 10.627a.781.781 0 00.781-.781V3.125A3.129 3.129 0 0013.439 0H6.251a3.129 3.129 0 00-3.125 3.123A3.129 3.129 0 00.001 6.248v10.627A3.129 3.129 0 003.125 20h7.579a3.129 3.129 0 003.128-3.125v-.024a3.13 3.13 0 002.735-3.1.781.781 0 10-1.563 0 1.565 1.565 0 01-1.562 1.563H6.253a1.563 1.563 0 01-1.563-1.563V3.125a1.565 1.565 0 011.563-1.563h7.189a1.565 1.565 0 011.56 1.563v6.72a.781.781 0 00.782.782zm-9.533 6.25h6.016a1.565 1.565 0 01-1.563 1.562H3.125a1.565 1.565 0 01-1.563-1.563V6.248a1.565 1.565 0 011.563-1.563v9.066a3.125 3.125 0 003.125 3.125z'
              fill={props.outercolor}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const SlidesMemoSvgComponent = React.memo(SlidesSvgComponent)
export default SlidesMemoSvgComponent
