import React, { ReactNode } from "react";
import { Checkbox } from "antd";
import { Field, FieldProps } from "formik";
import "./formStyle.scss";
import { WSErrorMessage } from ".";

const CheckboxGroup = Checkbox.Group;
interface LabelArrProps {
  value: string;
  label: ReactNode | string;
  qty?: number;
  disable?: boolean;
  color?: string;
}
interface SelectProps {
  labelarr?: LabelArrProps[] | string[];
  className?: string;
  name?: string;
  value?: string[];
  showerror?: boolean;
}

const WSCheckbox = (props: SelectProps) => {
  const { labelarr, name = "", value, showerror = true } = props;

  return (
    <>
      <Field name={name}>
        {({ form }: FieldProps) => (
          <CheckboxGroup
            onChange={(e) => form.setFieldValue(name, e)}
            options={labelarr}
            value={value}
            {...props}
          />
        )}
      </Field>
      {showerror && <WSErrorMessage name={name} />}
    </>
  );
};

export default WSCheckbox;
