import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function VIPContentSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      data-name='Group 299'
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}
    >
      <path
        data-name='Path 2143'
        d='M15.783 8.409a1.51 1.51 0 00-1.456-1.066h-2.171l-.661-2.032a1.533 1.533 0 00-2.913 0l-.66 2.031H5.751a1.51 1.51 0 00-1.456 1.065 1.532 1.532 0 00.558 1.72l1.746 1.26-.666 2.037a1.53 1.53 0 002.354 1.71l1.751-1.264 1.751 1.264a1.512 1.512 0 001.8-.005 1.5 1.5 0 00.551-1.7l-.666-2.037 1.746-1.26a1.532 1.532 0 00.563-1.723zm-3.24 1.729a1.519 1.519 0 00-.558 1.71l.643 1.965-1.69-1.22a1.535 1.535 0 00-1.8 0l-1.69 1.22.643-1.965a1.52 1.52 0 00-.558-1.71L5.827 8.906h2.107a1.517 1.517 0 001.457-1.033l.646-1.987.646 1.989a1.517 1.517 0 001.456 1.031h2.108z'
        fill={props.outercolor}
      />
      <path
        data-name='Path 2144'
        d='M17.071 2.929A10 10 0 002.929 17.071a10 10 0 0012.245 1.488.781.781 0 10-.809-1.336 8.512 8.512 0 112.642-2.54.781.781 0 001.3.869 9.975 9.975 0 00-1.236-12.623z'
        fill={props.innercolor}
      />
    </svg>
  )
}

const VIPContentMemoSvgComponent = React.memo(VIPContentSvgComponent)
export default VIPContentMemoSvgComponent
