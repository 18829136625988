import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function Q_A_WithSponsorSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19.998}
      height={20}
      viewBox='0 0 19.998 20'
      {...props}
    >
      <g data-name='Group 284' transform='translate(-1)'>
        <circle
          data-name='Ellipse 42'
          cx={0.781}
          cy={0.781}
          r={0.781}
          transform='translate(11.819 5.704)'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2117'
          d='M6.351 5.704a.781.781 0 10.781.781.781.781 0 00-.781-.781z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2118'
          d='M20.216 4.453a.781.781 0 00-.781.781v8.2a1.564 1.564 0 01-1.562 1.562 2.424 2.424 0 00-2.422 2.421v.575l-1.7-1.7a4.394 4.394 0 00-3.127-1.3h-.64a.781.781 0 100 1.562h.64a2.841 2.841 0 012.022.838l2.234 2.234a1.25 1.25 0 002.134-.884v-1.328a.86.86 0 01.859-.859 3.128 3.128 0 003.125-3.125v-8.2a.781.781 0 00-.782-.777z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2119'
          d='M9.475 5.704a.781.781 0 10.781.781.781.781 0 00-.781-.781z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2120'
          d='M11.374 12.89h3.374a3.128 3.128 0 003.125-3.125v-6.64A3.128 3.128 0 0014.748 0H4.125A3.128 3.128 0 001 3.125v6.641a3.128 3.128 0 003.125 3.124.86.86 0 01.859.859v1.328a1.25 1.25 0 002.134.884l2.234-2.234a2.841 2.841 0 012.022-.837zm-4.828 1.434v-.574a2.424 2.424 0 00-2.422-2.422 1.564 1.564 0 01-1.562-1.562V3.125a1.564 1.564 0 011.563-1.563h10.623a1.564 1.564 0 011.562 1.562v6.642a1.564 1.564 0 01-1.562 1.562h-3.374a4.393 4.393 0 00-3.127 1.3z'
          fill={props.outercolor}
        />
      </g>
    </svg>
  )
}

const Q_A_WithSponsorMemoSvgComponent = React.memo(Q_A_WithSponsorSvgComponent)
export default Q_A_WithSponsorMemoSvgComponent
