import React from "react";
import "./WSInput.scss";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import { Field, FieldProps } from "formik";
import { WSErrorMessage } from "components/formComponent";

interface CustomInputProps extends InputProps {
  label?: string;
  name: string;
}

const MyInput = ({field , ...props} : FieldProps & InputProps) => <Input {...props} {...field} />;

export const WSInput = ({label, name ,...props}: CustomInputProps) => {
  
  return (
    <div className="ws-input">
      <div className="input-label">
      <label>{label}</label>
      </div>
      <Field name={name} component={MyInput} {...props}  />
      <WSErrorMessage name={name}/>
    </div>
  );
};
