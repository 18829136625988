import { message as notification } from "antd";
import moment from "moment";
import { images } from "components/utility/image";
import { Document_Type, LOCAL_STORAGE_KEY, Regex, THEME_TYPE } from "components/utility/enums";
import History from "History";
import RouterPath from "routes/RouterPath";

// Slides page pdf,word,doc
export const documentTypeChange = (documentType: string) => {
  const { slidesPdf, slidesDoc, slidesWord } = images;
  switch (documentType) {
    case Document_Type.PDF:
      return slidesPdf;
    case Document_Type.DOC:
      return slidesDoc;
    case Document_Type.WORD:
      return slidesWord;
  }
};

export const camelCaseToNormalCase = (word: string) => {
  const result = word && word.replace(/([A-Z])/g, " $1");
  const finalResult =
    result && result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const requiredField = (fieldName: string) => {
  return `${fieldName} is required`;
};

export const getLocalStorageItem = (key = "") => {
  if (typeof localStorage !== 'undefined') return localStorage.getItem(key) || null;
  else return null
};

export const colorChecker = (color: string): THEME_TYPE => {
  let r: number, g: number, b: number, hsp: number

  const colorList: RegExpMatchArray | null = color.substring(1).match(/.{1,2}/g)
  if (colorList) {
    r = parseInt(colorList[0], 16)
    g = parseInt(colorList[1], 16)
    b = parseInt(colorList[2], 16)
  } else {
    r = g = b = 0
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  if (hsp > 127.5) {
    return THEME_TYPE.light
  } else {
    return THEME_TYPE.dark
  }

}


export const setLocalStorageItem = (key = "", string = "") => {
  if (typeof localStorage !== 'undefined') return localStorage.setItem(key, string);
  else return null
};

export const removeLocalStorageItem = (key = "") => {
  if (typeof localStorage !== 'undefined') return localStorage.removeItem(key);
  else return null
};
export const localStorageClear = () => {
  if (typeof localStorage !== 'undefined') return localStorage.clear();
  else return null
};

export const getLocalInfo = (key = "") => {
  if (typeof localStorage !== 'undefined') return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key) || "")
    : false;
  else return null
};

// Notification Show
export const NotificationWithIcon = (type: string, message: string) => {
  if (type === "success") {
    notification.success(message);
  } else if (type === "error") {
    notification.error(message);
  } else {
    notification.info(message);
  }
};

// select component
export const objectToSingleDataArray = (arr: Array<any>, key: string, passed: string) => {
  let output;

  if (key && passed) {
    output =
      arr &&
      arr.map((data) => {
        return {
          value: data[passed],
          label: data[key],
        };
      });
  } else {
    output =
      arr &&
      arr.map((data) => {
        return {
          value: data?.value,
          label: data?.label,
        };
      });
  }

  return output;
};

export const mergeByProperty: any = (
  target: any[],
  source: any[],
  prop: string
) => {
  return (
    target &&
    target.map((item) => {
      return {
        ...item,
        ...source.find((element) => element[prop] === item[prop]),
      };
    })
  );
};

export const removePTag = (data: string) => {
  const first = data.replace("<p>", "");
  const sec = first.replace("</p>", "");
  return sec.replace("&nbsp;", " ");
};

export const isoToFormat = (date: string, format: string) => {
  return date ? moment(date).format(format) : moment().format(format)
}

export const DateTimeFormat = (
  date: string,
  format: string,
  dateTimeFormat?: string
) => {
  // return moment(
  //   date && dateTimeFormat
  //     ? moment(date, dateTimeFormat)
  //     : date
  //       ? moment(date, dateTimeFormat)
  //       : moment()
  // ).format(format);
  return moment(
    date && dateTimeFormat
      ? moment(date,moment.ISO_8601)
      : date
        ? moment(date, moment.ISO_8601)
        : moment()
  ).format(format);
};

// to check if current time is in between given time slots
export function TimeCheck(time: string) {
  let eTime = new Date(`${time?.split(" to ")[1]}`).getTime();
  const cTime = new Date().getTime();
  return cTime < eTime;
}

// For image Upload
export const splitUrlFromUnderscore = (
  splitValue: string,
  url: string,
  pos?: any
) => {
  const splitUrl = url ? url.split(splitValue) : "";
  return splitUrl[pos || splitUrl.length - 1];
};

export const nameFormatter = (stringArray: string[], separator = " ") => {
  return stringArray.filter(Boolean).join(separator);
};

// logout
export function removeStorageAndLogOut(
  history?: any,
  message?: string
) {
  const {
    EVENT_CODE,
    EVENT_DATA,
    EVENT_SLUG,
    USER_ACCESS,
    AUTH_TOKEN,
    USER_INFO,
    APP_URL,
    PLAYED_SEC,
    VIDEO_DATA,
    VIDEO_REDIRECT_BACK
  } = LOCAL_STORAGE_KEY;
  const eventSlug = getLocalStorageItem(EVENT_SLUG) || "";
  removeLocalStorageItem(AUTH_TOKEN);
  removeLocalStorageItem(USER_INFO);
  removeLocalStorageItem(EVENT_DATA);
  removeLocalStorageItem(USER_ACCESS);
  removeLocalStorageItem(EVENT_CODE);
  removeLocalStorageItem(APP_URL);
  removeLocalStorageItem(PLAYED_SEC);
  removeLocalStorageItem(VIDEO_DATA);
  removeLocalStorageItem(VIDEO_REDIRECT_BACK);
  message && NotificationWithIcon("error", message);
  window.location.reload()
  // history ? history.push(RouterPath.CheckOverview) : window.location.reload(true);
}

// Group Data alphabetically

export function Grouping(data: any) {
  return (
    data
      ?.slice()
      .sort((rec1: { name: string }, rec2: { name: string }) =>
        rec1.name.localeCompare(rec2.name)
      )
      .reduce((result: any, e: { name: string }) => {
        const key = e.name[0];
        if (!result[key]) result[key] = [];
        result[key].push(e);
        return result;
      }, {}) || {}
  );
}

// Schedule Grouping
export function ScheduleGrouping(data: any) {
  return (
    data?.slice().reduce((result: any, e: { sessionTime: string }) => {
      const key = isoToFormat(e.sessionTime.split(" to ")[0], 'h:mm a');
      if (!result[key]) result[key] = [];
      result[key].push(e);
      return result;
    }, {}) || {}
  );
}

export function VIPContentGrouping(data: any) {
  return (
    data
      ?.slice()
      .reduce((result: any, e: { select: { _id: string; name: string } }) => {
        const key = e.select.name;
        if (!result[key]) result[key] = [];
        result[key].push(e);
        return result;
      }, {}) || {}
  );
}
