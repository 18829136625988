import React from "react";
import Password, { PasswordProps } from "antd/lib/input/Password";
import { Field, FieldProps } from "formik";
import { WSErrorMessage } from "components/formComponent";

interface CustomPasswordProps extends PasswordProps {
    label?: string;
    name: string;
  }

const MyPassword = ({field , ...props} : FieldProps & PasswordProps) => <Password {...props} {...field} />;

export const WSPassword: React.FC<CustomPasswordProps> = ({label, name ,...props}) => {
  return (
    <div className="ws-input">
      <div className="input-label">
      <label>{label}</label>
      </div>
      <Field name={name} component={MyPassword} {...props} />
      <WSErrorMessage name={name}/>
    </div>
  );
};
