

import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import { createRoot } from "react-dom/client";
import * as serviceWorker from 'serviceWorker'
import 'assets/css/app.scss'

// ReactDOM.render(<App />, document.getElementById('root'))
const rootElement = document.getElementById("root");
const root = createRoot(rootElement  as HTMLElement);
root.render(
//   <React.StrictMode>
    <App/>
//   </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()