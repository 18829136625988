import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function NetworkingSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}
    >
      <g data-name='Group 273'>
        <path
          data-name='Path 2095'
          d='M10 0a2.344 2.344 0 102.344 2.344A2.346 2.346 0 0010 0zm0 3.125a.781.781 0 11.781-.781.782.782 0 01-.781.781z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2096'
          d='M14.297 12.43a2.748 2.748 0 01-1.327 2.345l-.056.034a1.178 1.178 0 00-.569 1v3.41a.781.781 0 01-1.562 0v-3.41a2.748 2.748 0 011.327-2.345l.054-.034a1.178 1.178 0 00.569-1V8.95c0-1.614-1.5-1.953-2.754-1.953s-2.754.339-2.754 1.953v3.469a1.177 1.177 0 00.578 1.01l.066.039a2.745 2.745 0 011.348 2.357v3.4a.781.781 0 01-1.562 0v-3.4a1.176 1.176 0 00-.578-1.01l-.066-.039a2.745 2.745 0 01-1.347-2.361V8.946a3.173 3.173 0 011.374-2.743 5.99 5.99 0 015.884 0 3.173 3.173 0 011.374 2.743z'
          fill={props.outercolor}
        />
        <path
          data-name='Path 2097'
          d='M3.907 1.562a2.344 2.344 0 102.344 2.344 2.346 2.346 0 00-2.344-2.344zm0 3.125a.781.781 0 11.781-.781.782.782 0 01-.781.781z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2098'
          d='M6.251 16.484v2.734a.781.781 0 01-1.562 0v-2.734a.781.781 0 011.563 0z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2099'
          d='M4.609 7.793a.781.781 0 01-.781.781c-2.266 0-2.266 1.064-2.266 1.465v3.32a1.177 1.177 0 00.469.938 2.748 2.748 0 011.094 2.188v2.734a.781.781 0 01-1.563 0v-2.734a1.177 1.177 0 00-.469-.937A2.748 2.748 0 010 13.36v-3.32a2.712 2.712 0 011.254-2.413 4.762 4.762 0 012.574-.614.781.781 0 01.781.78z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2100'
          d='M16.094 1.562a2.344 2.344 0 102.344 2.344 2.346 2.346 0 00-2.344-2.344zm0 3.125a.781.781 0 11.781-.781.782.782 0 01-.781.781z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2101'
          d='M15.313 16.484v2.734a.781.781 0 01-1.562 0v-2.734a.781.781 0 011.563 0z'
          fill={props.innercolor}
        />
        <path
          data-name='Path 2102'
          d='M20 10.039v3.32a2.748 2.748 0 01-1.094 2.188 1.177 1.177 0 00-.469.938v2.734a.781.781 0 01-1.562 0v-2.734a2.748 2.748 0 011.094-2.187 1.177 1.177 0 00.469-.937v-3.32c0-.4 0-1.465-2.266-1.465a.781.781 0 010-1.562 4.762 4.762 0 012.574.614A2.712 2.712 0 0120 10.039z'
          fill={props.innercolor}
        />
      </g>
    </svg>
  )
}

const NetworkingMemoSvgComponent = React.memo(NetworkingSvgComponent)
export default NetworkingMemoSvgComponent
