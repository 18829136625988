import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function SponsorSvgComponent(props: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20.002}
      viewBox='0 0 20 20.002'
      {...props}
    >
      <g data-name='Group 277' transform='translate(-.021)'>
        <g data-name='Group 276'>
          <path
            data-name='Path 2109'
            d='M19.812 1.812a.781.781 0 00-.572-.249h-3.17q.008-.382.008-.781A.781.781 0 0015.3 0H4.748a.781.781 0 00-.781.781q0 .4.008.781H.8a.781.781 0 00-.777.838 9.69 9.69 0 001.122 3.466 12.854 12.854 0 002.266 3.08 15.9 15.9 0 004.059 2.926A8.75 8.75 0 019.241 14.6v1.27a10.948 10.948 0 00-1.509.534c-2.014.9-2.436 2.025-2.436 2.814a.781.781 0 00.78.782h7.813a.781.781 0 00.781-.781c0-.768-.414-1.875-2.387-2.787a11.029 11.029 0 00-1.48-.549v-1.34a8.4 8.4 0 011.748-2.66A15.187 15.187 0 0017.644 7.8a.782.782 0 10-1.229-.966 12.172 12.172 0 01-1.6 1.674A15.868 15.868 0 0016 3.125h2.3c-.044.171-.1.363-.17.573a.782.782 0 101.482.5 7.886 7.886 0 00.407-1.798.782.782 0 00-.207-.588zM2.568 5.215a9.6 9.6 0 01-.825-2.089h2.3a15.85 15.85 0 001.192 5.393 11.506 11.506 0 01-2.667-3.304zM12.61 18.439H7.344a4.692 4.692 0 011-.6 9.764 9.764 0 011.672-.557 7.511 7.511 0 012.594 1.157zm.04-9.208a15.162 15.162 0 01-1.25 1.594 14.9 14.9 0 00-1.392 1.8 15.783 15.783 0 00-1.383-1.81 16.071 16.071 0 01-1.232-1.584C6.176 7.364 5.609 5.042 5.537 1.563h8.97c-.072 3.479-.639 5.801-1.856 7.668z'
            fill={props.outercolor}
          />
        </g>
        <circle
          data-name='Ellipse 30'
          cx={1.953}
          cy={1.953}
          r={1.953}
          transform='translate(8.068 3.594)'
          fill={props.innercolor}
        />
      </g>
    </svg>
  )
}

const SponsorMemoSvgComponent = React.memo(SponsorSvgComponent)
export default SponsorMemoSvgComponent
