

import React from 'react'
import loadable from '@loadable/component'
import {  Route, Routes, useNavigate, useNavigation } from 'react-router-dom'
// component
import { WSSpin } from 'components/common/WSspinner/WSSpinner'
// constant
import { getLocalStorageItem } from 'utils/helper'
import RouterPath from './RouterPath'
import { SmallScreenVideoProps } from 'utils/CommonFormTypes'



// import



// interface Options {
//   navigate: Function
// }

const fallBackProp = { fallback: <WSSpin /> }

const LoginScreen = loadable(
  () => import('containers/auth/login/LoginForm'),
  // fallBackProp
)
const VerifyLogin = loadable(
  () => import('containers/auth/verifyLogin/verifyLogin'),
  // fallBackProp
)
const VerifyOverview = loadable(
  () => import('containers/auth/verifyLogin/verifyOverview'),
  // fallBackProp
)
const RegisterScreen = loadable(
  () => import('containers/auth/register/registerHandler'),
  // fallBackProp
)
const ForgotPasswordEmail = loadable(
  () => import('containers/auth/forgotPassword/ForgotPasswordHandler'),
  // fallBackProp
)
const SetPassword = loadable(
  () => import('containers/auth/setNewPassword/SetNewPasswordHandler'),
  // fallBackProp
)
const ChangePassword = loadable(
  () => import('containers/auth/changePassword/ChangePasswordHandler'),
  // fallBackProp
)
const VerifyRegister = loadable(
  () => import('containers/auth/verifyRegister/VerifyRegister'),
  // fallBackProp
)
const SetPasswordSuccessScreen = loadable(
  () => import('containers/auth/setNewPassword/SuccessPassword'),
  // fallBackProp
)
const InstructionSent = loadable(
  () => import('containers/auth/instructionSent/InstructionSent'),
  // fallBackProp
)

const LiveStream = loadable(
  () => import('containers/liveStream/LiveStream'),
  // fallBackProp
)
const Overview = loadable(
  () => import('containers/overview/Overview'),
  // fallBackProp
)
const VirtualExpo = loadable(
  () => import('containers/virtualExpo/virtualExpo'),
  // fallBackProp
)
const Networking = loadable(
  () => import('containers/networking/Networking'),
) 
  // fallBackPropimport { getLocalStorageItem } from 'utils/helper'
const JoinLiveStream = loadable(
  () => import('containers/liveStream/JoinLiveStream'),
  // fallBackProp
)
const LivePolls = loadable(
  () => import('containers/livePollsQA/LivePollsQA'),
  // fallBackProp
)
const Survey = loadable(() => import('containers/survey/Survey'), 
// fallBackProp
)
const UserDetail = loadable(
  () => import('components/userDetail/UserDetail'),
  // fallBackProp
)
const BreakoutSession = loadable(
  () => import('containers/breakoutSession/BreakoutSession'),
  // fallBackProp
)
const JoinBreakoutSession = loadable(
  () => import('containers/breakoutSession/JoinBreakoutSessionHandler'),
  // fallBackProp
)
const Sponsors = loadable(
  () => import('containers/sponsors/Sponsors'),
  // fallBackProp
)
const SponsorsDetail = loadable(
  () => import('components/sponsersDetail/SponsorsDetail'),
  // fallBackProp
)
const SponsorsScheduleMeetingDetail = loadable(
  () => import('components/scheduleMeetingDetail/ScheduleMeetingDetail'),
  // fallBackProp
)
const PresentersScheduleMeetingDetail = loadable(
  () => import('components/presenterMeetingDetail/PresenterMeetingDetail'),
  // fallBackProp
)
const Schedule = loadable(
  () => import('containers/schedule/Schedule'),
  // fallBackProp
)
const Announcements = loadable(
  () => import('containers/announcements/Announcements'),
  fallBackProp
)
const Presenters = loadable(
  () => import('containers/presenters/Presenters'),
  // fallBackProp
)
const PresentersDetail = loadable(
  () => import('components/presentersDetail/PresentersDetail'),
  // fallBackProp
)
const Recordings = loadable(
  () => import('containers/recordings/Recordings'),
  // fallBackProp
)
const HelpDesk = loadable(
  () => import('containers/helpDesk/HelpDesk'),
  // fallBackProp
)

const RecordingsDetail = loadable(
  () => import('components/recordingsDetail/RecordingsDetail'),
  // fallBackProp
)
const AppGuideDetail = loadable(
  () => import('components/recordingsDetail/RecordingsDetail'),
  // fallBackProp
)
const Slides = loadable(() => import('containers/slides/Slides'), 
// fallBackProp
)
const AppGuide = loadable(
  () => import('containers/appGuide/AppGuide'),
  // fallBackProp
)
const VipContent = loadable(
  () => import('containers/vipContent/VipContent'),
  // fallBackProp
)
const StreamOptions = loadable(
  () => import('containers/streamOptions/StreamOptions'),
  // fallBackProp
)
const StreamOptionDetail = loadable(
  () => import('components/streamOptionDetail/StreamOptionDetail'),
  // fallBackProp
)

// for future
// const SearchPage = loadable(
//   () => import("components/common/SearchPage/SearchPage"),
// fallBackProp
// );
const VipContentDetail = loadable(
  () => import('components/vipContentDetail/VipContentDetail'),
  // fallBackProp
)
const VipContentBookTime = loadable(
  () => import('components/vipContentBookTimeDetail/VipContentBookTime'),
  // fallBackProp
)
const AmaSessions = loadable(
  () => import('containers/amaSessions/AmaSessions'),
  // fallBackProp
)
const AMAUserSession = loadable(
  () => import('containers/AMAUserSession/AMAUserSession'),
  // fallBackProp
)
const BookMeetings = loadable(
  () => import('containers/bookMeetings/BookMeetings'),
  // fallBackProp
)
const Schedule1v1 = loadable(
  () => import('containers/Schedule1v1'),
  // fallBackProp
)
const Profile = loadable(
  () => import('containers/profile/ProfileHandler'),
  // fallBackProp
)
const PageNotFound = loadable(
  () => import('containers/auth/pageNotFound/PageNotFound'),
  // fallBackProp
)
const AppRoutes: React.FC<SmallScreenVideoProps> = (props) => {
  let navigate = useNavigate()
  let eventSlug : String | any = getLocalStorageItem('eventSlug')
  // props.eventSulg = eventSlug
  const options = {
    navigate :navigate as any,
    eventSlug :eventSlug as String

  }
  return (
    <Routes>
      {/*for future */}
      {/* <Route path={RouterPath.SearchPage}  element={SearchPage} /> */}
      <Route
        path={RouterPath.Overview}
        
        element={<Overview {...props} />}
      />
      <Route
        path={RouterPath.VirtualExpo}
        
        element={<VirtualExpo {...props} eventSlug={eventSlug}/>}
      />
      <Route
        path={RouterPath.Networking}
        element={<Networking {...props} eventSlug={eventSlug} />}
        
      />
      <Route path={RouterPath.UserDetail} element={<UserDetail/>}  />
      <Route
        path={RouterPath.LiveStream}
        element={<LiveStream {...props} />}
        
      />
      <Route
        path={RouterPath.JoinLiveStream}
        element={<JoinLiveStream eventSlug={eventSlug!}/>}
        
      />
      <Route
        path={RouterPath.LivePolls}
        element={<LivePolls {...props} />}
        
      />
      <Route
        path={RouterPath.Survey}
        element={<Survey {...props} />}
        
      />
      <Route
        path={RouterPath.BreakoutSession}
        element={<BreakoutSession />}
        
      />
      <Route
        path={RouterPath.JoinBreakoutSession}
        element={<JoinBreakoutSession {...options}/>}
        
      />
      <Route
        path={RouterPath.Sponsors}
        element={<Sponsors {...props} eventSlug={eventSlug}/>}
        
      />
      <Route
        path={RouterPath.SponsorsDetail}
        element={<SponsorsDetail/>}
        
      />
      <Route
        path={RouterPath.SponsorsScheduleMeetingDetail}
        element={<SponsorsScheduleMeetingDetail/>}
        // initValue={history}
        
      />
      <Route
        path={RouterPath.PresentersScheduleMeetingDetail}
        element={<PresentersScheduleMeetingDetail/>}
        // initValue={history}
        
      />
      <Route
        path={RouterPath.Schedule}
        element={<Schedule {...props} eventSlug={eventSlug} />}
        
      />
      <Route
        path={RouterPath.Announcements}
        element={<Announcements {...props} eventSlug={eventSlug} />}
        
      />
      <Route
        path={RouterPath.Presenters}
        element={<Presenters {...props} eventSlug={eventSlug} />}
        
      />
      <Route
        path={RouterPath.PresentersDetail}
        element={<PresentersDetail/>}
        
      />
      <Route
        path={RouterPath.Recordings}
        element={<Recordings {...props} eventSlug={eventSlug} />}
        
      />
      <Route
        path={RouterPath.RecordingsDetail}
        element={<RecordingsDetail {...props} />}
        
      />
      <Route
        path={RouterPath.AppGuideDetail}
        element={<AppGuideDetail/>}
        
      />
      <Route
        path={RouterPath.HelpDesk}
        element={<HelpDesk {...props} />}
        
      />
      <Route
        path={RouterPath.Slides}
        element={<Slides {...props} />}
        
      />
      <Route
        path={RouterPath.AppGuide}
        element={<AppGuide {...props} />}
        
      />
      <Route
        path={RouterPath.VipContent}
        element={<VipContent {...props} />}
        
      />
      <Route
        path={RouterPath.StreamOptions}
        element={<StreamOptions {...props} eventSlug={eventSlug}  />}
        
      />
      <Route
        path={RouterPath.StreamOptionDetail}
        element={<StreamOptionDetail/>}
        
      />

      <Route
        path={RouterPath.VipContentDetail}
        element={<VipContentDetail/>}
        
      />
      <Route
        path={RouterPath.VipContentBookTime}
        element={<VipContentBookTime/>}
        
      />
      <Route
        path={RouterPath.AmaSessions}
        element={<AmaSessions {...props} eventSlug={eventSlug} />}
        
      />
      <Route
        path={RouterPath.AMAUserSession}
        element={<AMAUserSession/>}
        
      />
      <Route path={RouterPath.BookMeetings} element={<BookMeetings eventSlug={eventSlug}/>}  />
      <Route
        path={RouterPath.Schedule1v1}
        element={<Schedule1v1 {...props} eventSlug={eventSlug} />}
        
      />
      <Route
        path={RouterPath.Profile}
        element={<Profile {...options}  />}
        
      />
      <Route
        path={RouterPath.ChangePassword}
        element={<ChangePassword {...options}/>}
        
      />
      <Route path={RouterPath.CheckOverview} element={<VerifyOverview/>}  />
      <Route path="*" element={<VerifyOverview/>}  />
      {/* <Redirect to={RouterPath.CheckOverview}  /> */}
    </Routes>
  )
}

const AuthRoutes: React.FC = (props:any) => {
  const navigate = useNavigate()
  let eventSlug = getLocalStorageItem('eventSlug')
  // console.log("eventSlug routes",eventSlug);
  // console.log("in auth route");

  const options = {
    navigate :navigate as any,
    eventSlug :eventSlug as String

  }
  const slug={
    eventSlug :eventSlug as String
  }
  return (
    <Routes>
      <Route path={RouterPath.LoginScreen} element={<LoginScreen/>}  />
      <Route path={RouterPath.VerifyLogin} element={<VerifyLogin/>}  />
      
      <Route
        path={RouterPath.RegisterScreen}
        // element={<RegisterScreen history={navigate}/>}
        element={<RegisterScreen {...options} />}
      />
       <Route
     path={RouterPath.VerifyRegister}
     element={<VerifyRegister {...slug}/>}
     
   />
        <Route path={RouterPath.SetPassword} element={<SetPassword {...props}/>}  />
        <Route
          path={RouterPath.SetPasswordSuccess}
          element={<SetPasswordSuccessScreen/>}
          
        />
      <Route
        path={RouterPath.ForgotPasswordEmail}
        element={<ForgotPasswordEmail {...props} {...options}/>}
        
      />
        {/* <Route
          path={RouterPath.VerifyRegister}
          element={<VerifyRegister/>}
          
        /> */}
      
   
      <Route path={RouterPath.ForgotPassword} element={<SetPassword {...props} {...slug}/>}  />
      <Route
        path={RouterPath.InstructionSent}
        element={<InstructionSent/>}
        
      />
    {/* <Redirect to='/:id'  /> */}
    <Route path="/" element={<PageNotFound/>} />  
    <Route path="*" element={<VerifyLogin/>} />
    </Routes>
  )
}

export { AppRoutes, AuthRoutes }
