import * as React from 'react'

interface SVGRProps {
  outercolor?: string
  innercolor?: string
}

function OverviewSvgComponent(
  props: React.SVGProps<SVGSVGElement> & SVGRProps
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}
    >
      <g data-name='Group 271'>
        <g data-name='Group 270'>
          <circle
            data-name='Ellipse 29'
            cx={1}
            cy={1}
            r={1}
            transform='translate(9.164 4)'
            fill={props.outercolor}
          />
          <path
            data-name='Path 2093'
            d='M17.071 2.929A10 10 0 002.929 17.071a10 10 0 0012.245 1.488.781.781 0 00-.81-1.336 8.513 8.513 0 112.642-2.539.781.781 0 001.3.868 9.975 9.975 0 00-1.235-12.623z'
            fill={props.innercolor}
          />
          <path
            data-name='Path 2094'
            d='M10.149 7.305a.865.865 0 00-.93.781v6.953a.944.944 0 001.86 0V8.086a.865.865 0 00-.93-.781z'
            fill={props.outercolor}
          />
        </g>
      </g>
    </svg>
  )
}

const OverviewMemoSvgComponent = React.memo(OverviewSvgComponent)
export default OverviewMemoSvgComponent
