import React, { useEffect, useState } from 'react'
import { WSButton } from 'components/common/WSbutton/WSButton'
// common component but giving error when imported
// import { videoStreamSelect } from 'components/core/joinAfter/JoinAfter'

import { images } from 'components/utility/image'
import { LOCAL_STORAGE_KEY, PROVIDERS, VIDEO_CLASS_NAME } from 'components/utility/enums'
import { CommonVideoProps } from 'constants/data'
import { SmallScreenVideoProps, VideoProps } from 'utils/CommonFormTypes'
import {
  getLocalInfo,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'utils/helper'
import { WSVideo } from 'components/common/WSvideo/WSVideo'
import { WSTitle } from 'components/common/WStitle/WSTitle'

const FloatingVideo = ({ setShowVideo, showVideo }: SmallScreenVideoProps) => {
  const { PLAYED_SEC, VIDEO_REDIRECT_BACK, VIDEO_DATA } = LOCAL_STORAGE_KEY
  let redirectUrl = getLocalStorageItem(VIDEO_REDIRECT_BACK) || ''
  let videoStorageData = getLocalInfo(VIDEO_DATA) || ''
  let defaultSecs = getLocalStorageItem(PLAYED_SEC)
  const [videoData, setVideoData] = useState<{
    url: string
    providerName?: string
  }>()
  const [playedSeconds, setPlayedSeconds] = useState<number>(0)
  const [directUrl, setDirectUrl] = useState<string>('')
  useEffect(() => {
    if (videoStorageData?.url) {
      setVideoData({
        url: videoStorageData?.url || '',
        providerName: videoStorageData?.providerName || '',
      })
      setDirectUrl(redirectUrl || '')
    }
    defaultSecs && setPlayedSeconds(Number(defaultSecs) || 0)
  }, [showVideo])
  const closeVideo = () => {
    removeLocalStorageItem(VIDEO_DATA)
    removeLocalStorageItem(VIDEO_REDIRECT_BACK)
    removeLocalStorageItem(PLAYED_SEC)
    setShowVideo && setShowVideo(false)
  }
  function videoStreamSelect(
    provider: string,
    meetingUrl: string,
    playedSeconds?: number,
    commonProps?: VideoProps
  ) {
    switch (provider) {
      case PROVIDERS.Facebook:
      case PROVIDERS.DailyMotion:
      case PROVIDERS.Twitch:
      case PROVIDERS.SoundCloud:
      case PROVIDERS.Streamable:
      case PROVIDERS.Wistia:
      case PROVIDERS.YouTube:
      case PROVIDERS.Vimeo:
      case PROVIDERS.ForRecordingPage:
        return (
          <WSVideo
            playAt={playedSeconds}
            divClassName={VIDEO_CLASS_NAME[provider]?.className}
            height='460px'
            smallScreenClassName='small-screen-player'
            url={meetingUrl}
            {...commonProps}
            onProgress={({ playedSeconds }) => {
              setLocalStorageItem(
                LOCAL_STORAGE_KEY.PLAYED_SEC,
                `${playedSeconds}`
              )
            }}
          />
        )
      case PROVIDERS.Zoom:
        return <WSTitle level={3}>Zoom meeting opens in new tab</WSTitle>
      default:
        return <p>No provider</p>
    }
  }

  return (
    <>
      {showVideo && videoData?.url && directUrl && (
        <div className='video-small-screen'>
          <div className='d-flex justify-content-between'>
            <WSButton
              onClick={() => {
                window.location.pathname = directUrl
                setShowVideo && setShowVideo(false)
                removeLocalStorageItem(VIDEO_REDIRECT_BACK)
              }}
              className='small-screen-video-back-button'
              imgSrc={images.prevArrowBlack}
              imgWidth='15px'
            />
            <WSButton
              onClick={closeVideo}
              className='small-screen-video-close-button'
              imgSrc={images.closeIcon}
              imgWidth='12px'
            />
          </div>
          {videoStreamSelect(
            videoData?.providerName || '',
            videoData?.url,
            playedSeconds,
            CommonVideoProps
          )}
        </div>
      )}
    </>
  )
}

export default FloatingVideo
